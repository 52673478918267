.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

ul.react-paginate {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  list-style-type: none;
  padding: 0 5rem;
}

ul.react-paginate li a {
  border-radius: 7px;
  padding: 0.1rem 1rem;
  border: gray 1px solid;
  cursor: pointer;
}
ul.react-paginate li.previous a,
ul.react-paginate li.next a,
ul.react-paginate li.break a {
  border-color: transparent;
}
ul.react-paginate li.selected a {
  background-color: #0366d6;
  border-color: transparent;
  color: white;
  min-width: 32px;
}
ul.react-paginate li.disabled a {
  color: grey;
}
ul.react-paginate li.disable,
ul.react-paginate li.disabled a {
  cursor: default;
}
